@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&family=Dancing+Script&family=Pacifico&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #f0f2f5;
}
a {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 100px;
  box-shadow: inset 2px 2px 5px 0 rgba(#888888, 0.5);
}

.ant-btn,
.ant-btn.btn-shadow {
  box-shadow:
    0 0 transparent,
    0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -webkit-box-shadow:
    0 0 transparent,
    0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -moz-box-shadow:
    0 0 transparent,
    0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -ms-box-shadow:
    0 0 transparent,
    0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
}
#root {
  height: 100%;
}
.anticon svg {
  vertical-align: baseline;
}

.inline-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(142, 142, 142, 0.4);
}

.login-page .ant-form-item {
  margin-bottom: 11px !important;
}
.login-page .input-login {
  height: 50px;
}
.content-wrapper {
  padding-bottom: 50px;
}
/* .ant-form-item-with-help {
  padding-bottom: 6px;
} */

.ant-table-thead.customize-header th {
  background-color: #333;
  color: #fff;
}

.receipt-wrapper {
  min-height: 480px;
}
.tab-name {
  cursor: pointer;
}
.text-login {
  color: #008efb;
}
.hr-login {
  background-color: #008efb;
  width: 70px;
}
.header-title {
  color: #fff !important;
  font-weight: bold;
}
.ant-layout-sider {
  background-color: #fff !important;
  box-shadow: 2px 2px 2px #ddd;
}
.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #333 !important;
  border-top: 1px solid #ddd;
}
.div-breadcrumb {
  background-color: #fff;
  /* height: 120px; */
  padding: 12px 24px;
  margin: -24px -24px 24px -24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.p-breadcrumb {
  color: #888;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}
.p-breadcrumb-title {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
.span-breadcrumb-title {
  color: #888;
  font-size: 12px;
}
.selectedClub {
  background-color: #e1f2ff;
}
.ant-table-tbody > tr.selectedClub.ant-table-row:hover > td {
  background-color: #e1f2ff !important;
}
.leaflet-container {
  width: 100%;
  min-height: calc(100vh - 250px);
  height: 100%;
}
.leaflet-popup-content p {
  margin: 5px 0;
}
.icon-map-location {
  border-radius: 100%;
  border: 3px solid#315dee;
}
.icon-map-location.icon-map-selected {
  border: 3px solid #36f11e;
}
.text-forget {
  color: #008efb;
  cursor: pointer;
}
.map-container {
  top: 200px;
  width: 100%;
  height: 100%;
  left: 105px;
}
.text-red {
  color: red !important;
}
.pointer {
  cursor: pointer;
}
.inputnumber-onlyread .ant-input-number-input {
  font-weight: bold !important;
  text-align: right;
  padding: 0;
}
.div-item-comment {
  border-bottom: 1px solid #ddd;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  word-break: break-all;
}
.div-item-comment:last-child {
  border-bottom: none;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
}
.comment-time {
  font-size: 12px;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer {
  width: calc(100% + 48px);
  background-color: #fff;
  margin: 20px -24px 0 -24px !important;
  padding: 10px 24px;
  font-size: 12px;
}
.login-footer {
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  padding: 10px 24px;
  font-size: 12px;
  z-index: 99;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.dashboard-title {
  background-color: #fff;
  padding: 10px 24px;
  margin-bottom: 20px;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
}
.tabs-card {
  background-color: #fff;
  border: 1px solid #ddd;
}
.tabs-card .ant-tabs-tab {
  width: 120px;
}
.splide__arrow--next {
  right: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow--prev {
  left: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow svg {
  fill: #333 !important;
}

.text-video {
  position: absolute;
  bottom: 20px;
  left: 60px;
  color: red;
  background-color: #315dee;
}
.text-ellipsis-3-row {
  display: block;
  display: -webkit-box;
  line-height: 20px;
  -webkit-line-clamp: 3; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  border: 1px dashed #315dee;
  background: transparent !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before {
  border: 1px dashed #315dee;
  background: transparent !important ;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 0%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0%;
}

.breadcrumb-sub {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.breadcrumb-sub p {
  margin-bottom: 0;
  text-align: right;
}

.breadcrumb-sub .breadcrumb-sub-item {
  position: relative;
  padding: 0 30px;
}

.breadcrumb-sub .breadcrumb-sub-item::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 2px;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.breadcrumb-sub .breadcrumb-sub-item:last-child {
  padding-right: 0;
}

.breadcrumb-sub .breadcrumb-sub-item:last-child::after {
  display: none;
}

.breadcrumb-sub .breadcrumb-sub-item .breadcrumb-sub-item__title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}

.breadcrumb-sub .breadcrumb-sub-item .breadcrumb-sub-item__content {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
}

.space-between-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.space-between-item p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1;
}

.fz-20 {
  font-size: 20px;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.support-member-list .ant-card-body {
  padding-top: 0;
}

.support-member-list
  .support-member
  + .support-member
  .support-member__position {
  border-top: 1px solid #f0f0f0;
}

.support-member__position {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0 -24px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 8px;
  font-size: 16px;
}

@media screen and (min-width: 1200px) {
  /* .map-container {
    position: fixed;
    width: 50%;
    left: 220px;
  } */
}
.upload-image-gift .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 300px;
}
.div-statistic-survey {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 0;
  text-align: center;
  margin-right: 20px;
  box-shadow: 3px 3px 3px #ddd;
}
.div-statistic-survey p {
  color: #9fa2b4;
  font-size: 18px;
}
.div-statistic-survey:last-child {
  margin-right: 0;
}
.shadow {
  box-shadow: 3px 3px 3px #ddd;
}

.static-upload .ant-upload-list-text .ant-upload-list-item-card-actions {
  display: none;
}

.tickets-event-tabs > .ant-tabs-nav {
  background: #fff;
  margin-bottom: 0;
}

.tickets-event-tabs .ant-tabs-tab {
  padding: 12px 20px;
}

.tickets-event-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.tickets-event-tabs .ant-tabs-content {
  background: #fff;
  padding: 15px 20px;
}

.tickets-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.p-i-0 {
  padding-inline: 0;
}

.btn-ellipsis {
  max-width: 100%;
  text-align: left;
  box-shadow: none;
}

.btn-ellipsis span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.btn-non-shadow {
  box-shadow: none;
}

/* Camera Component */
.camera-preview-box {
  position: absolute;
  inset: 0;
}

.camera-preview-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.banner-img-upload .ant-upload-list-picture-card-container {
  aspect-ratio: 4/3;
}

.cursor-not-allowed {
  cursor: not-allowed;
  pointer-events: none;
  color: #888;
  text-decoration: line-through;
}

.custom-tooltip-group-chart {
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
}

.form-customer-sending .ant-input-disabled,
.form-customer-sending .ant-select-disabled .ant-select-selector,
.form-customer-sending .ant-picker-disabled .ant-picker-input input {
  color: black !important;
}

body {
  margin: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tree-draggable-icon {
  flex: 0 0 auto;
}

.custom-card-for-chart-drop-line .ant-card-head-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-card-for-chart-drop-line .ant-card-head-title {
  width: 100%;
  padding-bottom: 0;
}

.custom-card-for-chart-drop-line .ant-card-extra {
  margin-top: 0px; /* Điều chỉnh khoảng cách nếu cần */
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.upload-picture-card-mini
  .ant-upload.ant-upload-select.ant-upload-select-picture-card,
.upload-picture-card-mini .ant-upload-list-picture-card-container {
  width: 100px !important;
  height: 100px !important;
}

.ck-editor__editable {
  font-family: 'Roboto', sans-serif !important;
}

.border-replied {
  position: relative;
}

.border-replied-parent::after {
  position: absolute;
  height: calc(100% - 20px);
  top: 40px;
  left: 13px;
  width: 12px;
  content: '';
  border-left: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
  border-bottom-left-radius: 10px;
}
.border-replied-child::after {
  position: absolute;
  height: calc(100% - 10px);
  top: 30px;
  left: 9px;
  width: 12px;
  content: '';
  border-left: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
  border-bottom-left-radius: 10px;
}

.disabled-row-report {
  color: #777 !important;
}

.tab-stand-alone .ant-tabs-nav-wrap {
  padding-left: 24px;
  background: #fff;
  padding-bottom: 12px;
}

