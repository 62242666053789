@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Dancing+Script&family=Pacifico&display=swap');

body {
  margin: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tree-draggable-icon {
  flex: 0 0 auto;
}

.custom-card-for-chart-drop-line .ant-card-head-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-card-for-chart-drop-line .ant-card-head-title {
  width: 100%;
  padding-bottom: 0;
}

.custom-card-for-chart-drop-line .ant-card-extra {
  margin-top: 0px; /* Điều chỉnh khoảng cách nếu cần */
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.upload-picture-card-mini
  .ant-upload.ant-upload-select.ant-upload-select-picture-card,
.upload-picture-card-mini .ant-upload-list-picture-card-container {
  width: 100px !important;
  height: 100px !important;
}

.ck-editor__editable {
  font-family: 'Roboto', sans-serif !important;
}

.border-replied {
  position: relative;
}

.border-replied-parent::after {
  position: absolute;
  height: calc(100% - 20px);
  top: 40px;
  left: 13px;
  width: 12px;
  content: '';
  border-left: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
  border-bottom-left-radius: 10px;
}
.border-replied-child::after {
  position: absolute;
  height: calc(100% - 10px);
  top: 30px;
  left: 9px;
  width: 12px;
  content: '';
  border-left: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
  border-bottom-left-radius: 10px;
}

.disabled-row-report {
  color: #777 !important;
}

.tab-stand-alone .ant-tabs-nav-wrap {
  padding-left: 24px;
  background: #fff;
  padding-bottom: 12px;
}
